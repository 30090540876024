

module.exports = {
  siteTitle: 'Sacred Garden | Therapy in Rochester, MI 48307', // <title>
  manifestName: 'Highlights',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: 'ffffff',
  manifestThemeColor: '#ffffff',
  manifestDisplay: 'standalone',
  manifestIcon: 'static/android-chrome-512x512.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Sacred Garden',
  subHeading: 'Cultivate the Self',
  // social
  socialLinks: [
    {
      icon: 'fab fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/sacredgardenwellness',
    },
    {
      icon: 'fab fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/sacredgardenwellness',
    },
    {
      icon: 'fas fa-envelope',
      name: 'Email',
      url: 'mailto:samantha@sacredgarden.cc',
    },
  ]
};
