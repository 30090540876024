import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer>
      <div>210 W. University Drive, Rochester, MI 48307, USA <span style={{marginLeft:"10px", marginRight:"10px"}}>248-906-8547</span> samantha@sacredgarden.cc</div>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} >
                <i className={`icon alt ${icon}`} title={`link to ${icon}`}></i>
                
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
}
